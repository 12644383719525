<template>
  <div class="home-container">
    <div class="hero-section">
      <div class="award-badge">
        <i class="fa fa-award"></i> Powerful image search technology
      </div>

      <h1 class="hero-title">
        <span class="facespy-brand">FaceSpy</span>
        <div class="hero-subtitle-main">
          Discover hidden OnlyFans profiles
        </div>
      </h1>

      <p class="hero-subtitle">
        We have indexed millions of OnlyFans profiles, including long time deleted ones.
        Upload an image and if profile ever existed, we'll find it.
      </p>

      <div class="upload-section">
        <image-uploader @check-em-clicked="navigateToResults" />
      </div>

      <!-- Clear call to action when no images selected -->
      <div class="cta-button-container" v-if="!hasUploadedImages">
        <button class="cta-button" @click="focusUploader">
          <i class="fa fa-upload"></i> Start Your Search Now
        </button>
    </div>

    <div class="stats-section">
      <div class="stat-item">
        <div class="stat-icon"><i class="fa fa-image"></i></div>
        <div class="stat-value">3M+</div>
        <div class="stat-label">OnlyFans Profiles</div>
      </div>

      <div class="stat-item">
        <div class="stat-icon"><i class="fa fa-video-camera"></i></div>
        <div class="stat-value">50M+</div>
        <div class="stat-label">Adult Videos</div>
      </div>

      <div class="stat-item">
        <div class="stat-icon"><i class="fa fa-search"></i></div>
        <div class="stat-value">99.8%</div>
        <div class="stat-label">Match Accuracy</div>
      </div>

      <div class="stat-item">
        <div class="stat-icon"><i class="fa fa-users"></i></div>
        <div class="stat-value">50k+</div>
        <div class="stat-label">Users Worldwide</div>
      </div>
    </div>

      <div class="hero-image-container">
        <img src="@/assets/hero.png" alt="Hero background" class="hero-background-image">
      </div>

      <div class="testimonials-section">
        <h3 class="testimonials-title">Trusted by thousands worldwide</h3>
        
        <div class="testimonials-carousel">
          <button class="carousel-nav prev" @click="prevTestimonialGroup" aria-label="Previous testimonials">
            <i class="fa fa-chevron-left"></i>
          </button>
          
          <div class="testimonials-viewport">
            <div class="testimonials-container" ref="testimonialsContainer" 
                @mouseenter="pauseCarousel" 
                @mouseleave="resumeCarousel"
                :style="{ transform: `translateX(-${currentSlide * 100}%)` }">
              <div class="testimonial-group" v-for="group in testimonialGroups" :key="group.id">
                <div v-for="testimonial in group.items" :key="testimonial.author" class="testimonial">
                  <img :src="testimonial.image" :alt="testimonial.author" class="testimonial-image">
                  <div class="testimonial-content">
                    <p class="testimonial-text">{{ testimonial.text }}</p>
                    <p class="testimonial-author">{{ testimonial.author }}</p>
                    <div class="testimonial-rating">
                      <i v-for="star in 5" :key="star" 
                        :class="['fa', star <= testimonial.stars ? 'fa-star' : star - 0.5 <= testimonial.stars ? 'fa-star-half-alt' : 'fa-star-o']"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <button class="carousel-nav next" @click="nextTestimonialGroup" aria-label="Next testimonials">
            <i class="fa fa-chevron-right"></i>
          </button>
          
          <div class="carousel-indicators">
            <button 
              v-for="index in Math.ceil(testimonials.length / testimonialsPerGroup)" 
              :key="index - 1" 
              :class="['indicator', { active: currentSlide === index - 1 }]"
              @click="goToSlide(index - 1)"
              :aria-label="`Go to testimonial group ${index}`"
            ></button>
          </div>
        </div>
      </div>

      <!-- Pricing Plans Section - hidden for premium users -->
      <div class="pricing-section" v-if="!isPremium">
        <h3 class="pricing-title">Choose Your Plan</h3>
        
        <div class="pricing-container">
          <!-- Free Plan -->
          <div class="pricing-plan">
            <div class="plan-header">
              <h4 class="plan-name">Free Forever</h4>
              <div class="plan-price">$0<span>/month</span></div>
            </div>
            <div class="plan-features">
              <div class="feature-item">
                <i class="fa fa-check feature-icon"></i>
                <span class="feature-text">10 searches per day*</span>
              </div>
              <div class="feature-item">
                <i class="fa fa-times feature-icon disabled"></i>
                <span class="feature-text">Uncensored Images</span>
              </div>
              <div class="feature-item">
                <i class="fa fa-times feature-icon disabled"></i>
                <span class="feature-text">Source Links</span>
              </div>
            </div>
            <button class="plan-button free" @click="focusUploader">Start Free</button>
            <p class="plan-footnote">*Fair use policy applies</p>
          </div>
          
          <!-- Premium Plan -->
          <div class="pricing-plan premium">
            <div class="popular-badge">Limited Time Offer</div>
            <div class="plan-header">
              <h4 class="plan-name">Premium</h4>
              <div class="plan-price">$20<span>/month</span></div>
            </div>
            <div class="plan-features">
              <div class="feature-item">
                <i class="fa fa-check feature-icon"></i>
                <span class="feature-text">Unlimited Searches*</span>
              </div>
              <div class="feature-item">
                <i class="fa fa-check feature-icon"></i>
                <span class="feature-text">Uncensored Images</span>
              </div>
              <div class="feature-item">
                <i class="fa fa-check feature-icon"></i>
                <span class="feature-text">Source Links</span>
              </div>
            </div>
            <button class="plan-button premium" @click="navigateToPremium">Get Premium</button>
            <p class="plan-footnote">*Fair use policy applies</p>
          </div>
        </div>
      </div>

      <!-- FAQ Section -->
      <div class="faq-section">
        <h3 class="faq-title">Frequently Asked Questions</h3>
        
        <div class="faq-container">
          <div 
            v-for="(item, index) in faqItems" 
            :key="index" 
            class="faq-item"
            :class="{ 'active': item.isOpen }"
          >
            <div class="faq-question" @click="toggleFaq(index)">
              <span>{{ item.question }}</span>
              <i class="fa" :class="item.isOpen ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
            </div>
            <div class="faq-answer" :class="{ 'open': item.isOpen }">
              <p>{{ item.answer }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { defineComponent } from "vue";
import ImageUploader from "@/components/ImageUploader.vue";
import FileStore from "@/services/FileStore";
import SearchLimitService from "@/services/SearchLimitService";
import PremiumService from "@/services/PremiumService";
import EventBus from "@/services/EventBus";
import GoogleAuthService from "@/services/GoogleAuthService";

export default defineComponent({
  inject: ["mixpanel"],
  name: "home-page",
  components: { ImageUploader },
  data() {
    return {
      files: [],
      hasUploadedImages: false,
      currentSlide: 0,
      carouselInterval: null,
      carouselSpeed: 5000, // 5 seconds per group
      testimonialsPerGroup: 3, // Number of testimonials to show at once
      currentWindowWidth: window.innerWidth, // Track window width for reactivity
      userIsPremium: false,
      testimonials: [
        {
          image: "https://storage.googleapis.com/similarity-finder/static/face_5.jpg",
          text: "\"FaceSpy helped me find my leaked embarrassing video I'd forgotten about. Amazing tool!\"",
          author: "Sarah Johnson",
          stars: 5
        },
        {
          image: "https://storage.googleapis.com/similarity-finder/static/face_2.jpg",
          text: "\"Discovered my fiancée had secrets I never knew about. Dodged a bullet before the wedding. Forever grateful.\"",
          author: "Michael Chen",
          stars: 5
        },
        {
          image: "https://storage.googleapis.com/similarity-finder/static/face_4.jpg",
          text: "\"Had suspicions about my girlfriend. FaceSpy revealed history she never mentioned. Confronted her and learned the truth before proposing.\"",
          author: "James Wilson",
          stars: 5
        },
        {
          image: "https://storage.googleapis.com/similarity-finder/static/face_3.jpg",
          text: "\"The accuracy is incredible. Found exactly what I was looking for within seconds.\"",
          author: "Emma Rodriguez",
          stars: 4.5
        },
        {
          image: "https://storage.googleapis.com/similarity-finder/static/face_1.jpg",
          text: "\"FaceSpy saved me from making a huge mistake. Found my partner's nasty past before I proposed. Worth every penny!\"",
          author: "David Thompson",
          stars: 5
        }
      ],
      // FAQ items data
      faqItems: [
        {
          question: "How does FaceSpy find OnlyFans profiles?",
          answer: "FaceSpy uses advanced image recognition technology to search our database of millions of indexed OnlyFans profiles. We can match faces across different images, even when they're taken from different angles or in different lighting conditions.",
          isOpen: false
        },
        {
          question: "What if a profile is deleted?",
          answer: "FaceSpy's indexing system has been running for years, during which we have archived millions of profiles, including those that have since been deleted. If a profile was active at the time of indexing, we will have a record of it.",
          isOpen: false
        },
        {
          question: "What information can I find about a person?",
          answer: "If we find a match, you'll be able to see all available public information about their OnlyFans profile, including name and profile URL.",
          isOpen: false
        },
        {
          question: "Is this service legal?",
          answer: "Yes. FaceSpy only searches publicly accessible information that has been legally obtained. We do not hack or access private accounts or content behind paywalls.",
          isOpen: false
        },
        {
          question: "How accurate are the results?",
          answer: "Our facial recognition technology has a 99.8% accuracy rate in identifying matches. For best results, upload clear photos showing the person's face from different angles.",
          isOpen: false
        },
        {
          question: "Can I use FaceSpy to verify someone before getting serious?",
          answer: "Absolutely. Many users rely on FaceSpy to verify potential partners' backgrounds and discover any hidden OnlyFans activity before committing to a serious relationship.",
          isOpen: false
        },
        {
          question: "Do you store uploaded images?",
          answer: "No, we do not store uploaded images or any information about the searches you perform. Your privacy is completely protected.",
          isOpen: false
        }
      ]
    };
  },
  computed: {
    // Group testimonials into arrays of 3 (or fewer for last group)
    testimonialGroups() {
      const groups = [];
      const itemsPerGroup = this.getTestimonialsPerGroup();
      
      for (let i = 0; i < this.testimonials.length; i += itemsPerGroup) {
        groups.push({
          id: i / itemsPerGroup,
          items: this.testimonials.slice(i, i + itemsPerGroup)
        });
      }
      
      return groups;
    },
    totalGroups() {
      return Math.ceil(this.testimonials.length / this.getTestimonialsPerGroup());
    },
    isPremium() {
      return this.userIsPremium;
    }
  },
  methods: {
    navigateToResults(files) {
      this.files = files;
      
      // Check if user is premium or has available searches
      const isPremium = PremiumService.isPremiumUser();
      
      if (!isPremium) {
        // Check if user has reached the daily limit
        const hasReachedLimit = SearchLimitService.hasReachedLimit();
        
        if (hasReachedLimit) {
          // Show modal or alert that limit is reached
          alert("You've reached your daily search limit (10 searches). Upgrade to premium for unlimited searches.");
          
          // Optionally, navigate to premium page
          this.navigateToPremium();
          return;
        }
        
        // Increment search counter for non-premium users
        const newCount = SearchLimitService.incrementSearchCount();
        
        // Notify other components about the updated search count
        EventBus.emit('search-count-updated');
      }
      
      // Enhanced tracking with more details
      this.mixpanel.track("search_initiated", {
        photo_count: files.length,
        total_size: files.reduce((sum, file) => sum + file.size, 0),
        file_types: files.map(file => file.type),
        is_premium: isPremium,
        search_count: !isPremium ? SearchLimitService.getCurrentCount() : null,
        search_limit: !isPremium ? SearchLimitService.getDailyLimit() : null
      });

      // Store files in the FileStore
      FileStore.setFiles(files);

      // Navigate to results page without files in params
      this.$router.push({ name: "results" });
    },
    navigateToPremium() {
      // Track premium upgrade click event
      this.mixpanel.track("premium_upgrade_clicked");
      
      // Check if user is logged in
      if (!GoogleAuthService.isLoggedIn()) {
        // console.log("User not logged in, redirecting to sign in");
        // Save intent to directly go to checkout after login
        localStorage.setItem('postLoginRedirect', '/premium/checkout');
        // Use the same sign-in method as the navbar
        GoogleAuthService.signIn('premium-button');
        return;
      }
      
      // If logged in, redirect to checkout directly 
      PremiumService.redirectToCheckout();
    },
    focusUploader() {
      // Scroll to the uploader component and trigger a click
      const uploaderElement = document.querySelector('.dropzone');
      if (uploaderElement) {
        uploaderElement.scrollIntoView({ behavior: 'smooth' });
        
        // Add a small delay before clicking to ensure smooth scroll completes
        setTimeout(() => {
          uploaderElement.click();
        }, 500);
      }
      
      this.mixpanel.track("cta_button_clicked");
    },
    // Get the number of testimonials per group based on screen size
    getTestimonialsPerGroup() {
      // On mobile (under 768px), show only 1 at a time
      if (this.currentWindowWidth < 768) {
        return 1;
      }
      // On medium screens (under 1200px), show 2 at a time
      else if (this.currentWindowWidth < 1200) {
        return 2;
      }
      // On larger screens, show 3 at a time
      else {
        return this.testimonialsPerGroup;
      }
    },
    // Carousel methods
    startCarousel() {
      this.carouselInterval = setInterval(() => {
        this.nextTestimonialGroup();
      }, this.carouselSpeed);
    },
    pauseCarousel() {
      clearInterval(this.carouselInterval);
    },
    resumeCarousel() {
      this.startCarousel();
    },
    nextTestimonialGroup() {
      this.currentSlide = (this.currentSlide + 1) % this.totalGroups;
    },
    prevTestimonialGroup() {
      this.currentSlide = (this.currentSlide - 1 + this.totalGroups) % this.totalGroups;
    },
    goToSlide(index) {
      this.currentSlide = index;
    },
    handleResize() {
      // Update current window width to trigger computed properties
      this.currentWindowWidth = window.innerWidth;
      
      // Force re-render of testimonial groups when window is resized
      this.$forceUpdate();
      
      // When window resizes significantly, go back to first slide to prevent empty views
      if (this.currentSlide >= this.totalGroups) {
        this.currentSlide = 0;
      }
    },
    // Toggle FAQ item open/closed
    toggleFaq(index) {
      this.faqItems[index].isOpen = !this.faqItems[index].isOpen;
      
      // Close other FAQs (accordion style)
      this.faqItems.forEach((item, i) => {
        if (i !== index) {
          item.isOpen = false;
        }
      });
    },
    updatePremiumStatus() {
      // Use Vue nextTick to ensure component is properly mounted
      this.$nextTick(() => {
        try {
          // Only proceed if the component is still mounted
          if (this._isDestroyed || this._isBeingDestroyed) {
            return;
          }
          
          const newPremiumStatus = PremiumService.isPremiumUser();
          if (this.userIsPremium !== newPremiumStatus) {
            // console.log("Premium status changed:", newPremiumStatus);
            this.userIsPremium = newPremiumStatus;
            
            // Safely force update only if component is still active
            if (!this._isDestroyed && !this._isBeingDestroyed) {
              this.$forceUpdate();
            }
          }
        } catch (error) {
          console.error("Error updating premium status:", error);
        }
      });
    }
  },
  created() {
    this.mixpanel.track("home_opened");
    
    // Set up listener for premium status changes - using a bound method to maintain context
    this.premiumStatusHandler = (isPremium) => {
      if (!this._isDestroyed && !this._isBeingDestroyed) {
        this.updatePremiumStatus();
      }
    };
    
    // Set up listener for auth events
    this.authStatusHandler = () => {
      if (!this._isDestroyed && !this._isBeingDestroyed) {
        // Give a short delay to ensure premium status is updated in the service
        setTimeout(() => {
          this.updatePremiumStatus();
        }, 500);
      }
    };
    
    // Register event handlers
    EventBus.on('premium-status-change', this.premiumStatusHandler);
    EventBus.on('user-authenticated', this.authStatusHandler);
    
    // Load premium status initially - deferred to avoid lifecycle issues
    this.$nextTick(() => {
      if (!this._isDestroyed && !this._isBeingDestroyed) {
        this.updatePremiumStatus();
      }
    });
  },
  mounted() {
    // Create mutation observer to detect when images are added
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.target.classList.contains('has-images')) {
          this.hasUploadedImages = true;
        } else if (mutation.oldValue && mutation.oldValue.includes('has-images') && !mutation.target.classList.contains('has-images')) {
          this.hasUploadedImages = false;
        }
      });
    });
    
    // Start observing the dropzone for class changes
    const dropzone = document.querySelector('.dropzone');
    if (dropzone) {
      observer.observe(dropzone, { 
        attributes: true, 
        attributeFilter: ['class'],
        attributeOldValue: true
      });
    }
    
    // Start the carousel
    this.startCarousel();
    
    // Add window resize listener for responsive behavior
    window.addEventListener('resize', this.handleResize);
    
    // Initial calculation of testimonials per group based on screen size
    this.$nextTick(() => {
      this.handleResize();
    });
    
    // Update premium status after component is mounted - with safety check
    this.$nextTick(() => {
      if (!this._isDestroyed && !this._isBeingDestroyed) {
        this.updatePremiumStatus();
      }
    });
  },
  beforeUnmount() {
    // Clean up the observer
    if (this.observer) {
      this.observer.disconnect();
    }
    
    // Clean up event listeners
    window.removeEventListener('resize', this.handleResize);
    
    // Clear the carousel interval
    clearInterval(this.carouselInterval);
    
    // Clean up EventBus listeners
    EventBus.off('premium-status-change', this.premiumStatusHandler);
    EventBus.off('user-authenticated', this.authStatusHandler);
  }
});
</script>

<style scoped>
.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px;
}

.hero-section {
  text-align: center;
  padding: 45px 16px;
  position: relative;
}

.award-badge {
  display: inline-flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  padding: 6px 14px;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 24px;
}

.award-badge i {
  color: #ffd700;
  margin-right: 6px;
}

.hero-title {
  margin-bottom: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.facespy-brand {
  display: block;
  font-size: 4rem;
  font-weight: 800;
  line-height: 1.1;
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0 2px 15px rgba(74, 116, 220, 0.3);
  letter-spacing: -1px;
  margin-bottom: 5px;
}

.hero-subtitle-main {
  font-size: 1.75rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  margin-top: 8px;
}

.gradient-text {
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.hero-subtitle {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.8);
  max-width: 700px;
  margin: 0 auto 32px;
  line-height: 1.6;
}

/* Results bullets section */
.results-bullets {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 0 auto 32px;
  max-width: 700px;
}

.result-bullet {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 12px 16px;
  border-radius: 10px;
  text-align: left;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.result-bullet:hover {
  transform: translateY(-2px);
  background-color: rgba(255, 255, 255, 0.08);
}

.result-icon {
  color: #4a74dc;
  font-size: 1.3rem;
  flex-shrink: 0;
}

.result-text {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  line-height: 1.4;
}

/* Clear CTA button */
.cta-button-container {
  margin: 32px auto;
}

.cta-button {
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  color: white;
  border: none;
  padding: 14px 28px;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: pulse 2s infinite;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.25);
  animation: none;
}

.cta-button i {
  font-size: 1.2rem;
}

@keyframes pulse {
  0% {
    box-shadow: 0 5px 15px rgba(74, 116, 220, 0.4);
  }
  50% {
    box-shadow: 0 5px 30px rgba(74, 116, 220, 0.6);
  }
  100% {
    box-shadow: 0 5px 15px rgba(74, 116, 220, 0.4);
  }
}

/* Testimonials section */
.testimonials-section {
  margin: 50px auto 40px;
  max-width: 900px;
  position: relative;
}

.testimonials-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 24px;
  color: rgba(255, 255, 255, 0.9);
}

.testimonials-carousel {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
  padding: 20px 0 40px; /* Add bottom padding for indicators */
}

.testimonials-viewport {
  overflow: hidden;
  width: 100%;
}

.testimonials-container {
  display: flex;
  transition: transform 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  width: 100%;
}

.testimonial-group {
  display: flex;
  flex: 0 0 100%;
  min-width: 100%;
  gap: 20px;
  justify-content: center;
}

.testimonial {
  flex: 1;
  min-width: 0;
  max-width: calc(33.33% - 14px);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
  text-align: center;
}

.testimonial:hover {
  transform: translateY(-5px);
  background-color: rgba(255, 255, 255, 0.08);
}

.testimonial-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid rgba(74, 116, 220, 0.5);
  margin-bottom: 16px;
}

.testimonial-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.testimonial-text {
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.5;
  margin-bottom: 16px;
  font-style: italic;
  flex: 1;
}

.testimonial-author {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 8px;
}

.testimonial-rating {
  color: #ffd700;
  font-size: 0.9rem;
  margin-top: auto;
}

.carousel-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  cursor: pointer;
  z-index: 5;
  opacity: 0.7;
  transition: opacity 0.3s ease, background-color 0.3s ease;
}

.carousel-nav:hover {
  opacity: 1;
  background: rgba(74, 116, 220, 0.8);
}

.carousel-nav.prev {
  left: 10px;
}

.carousel-nav.next {
  right: 10px;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  z-index: 10;
}

.indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  padding: 0;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.indicator.active {
  background-color: #4a74dc;
  transform: scale(1.2);
}

/* Remove scrolling behavior from the testimonials container */
.testimonials-container {
  overflow: visible;
  padding: 10px 0;
}

.upload-section {
  max-width: 900px;
  margin: 0 auto;
}

.hero-image-container {
  position: relative;
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.hero-background-image {
  max-width: 100%;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.stats-section {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 50px;
  flex-wrap: wrap;
}

.stat-item {
  text-align: center;
  padding: 16px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  min-width: 180px;
}

.stat-icon {
  font-size: 1.75rem;
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.7);
}

.stat-value {
  font-size: 2.2rem;
  font-weight: 700;
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 4px;
}

.stat-label {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 768px) {
  .facespy-brand {
    font-size: 3rem;
  }

  .hero-subtitle-main {
    font-size: 1.3rem;
  }

  .stats-section {
    gap: 16px;
  }

  .stat-item {
    min-width: 140px;
  }
  
  .testimonial {
    flex: 0 0 100%;
    min-width: 100%;
    max-width: 100%;
  }
  
  .testimonial-group {
    gap: 0;
  }
  
  .carousel-nav {
    width: 36px;
    height: 36px;
    font-size: 0.9rem;
  }
  
  .results-bullets {
    padding: 0 10px;
  }
  
  .cta-button {
    padding: 12px 24px;
    font-size: 1rem;
  }
}

@media (min-width: 769px) and (max-width: 1199px) {
  .testimonial {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
}

/* Pricing Plans Section */
.pricing-section {
  margin: 60px auto;
  max-width: 900px;
  padding: 0 16px;
  text-align: center;
}

.pricing-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 40px;
  color: rgba(255, 255, 255, 0.9);
}

.pricing-container {
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
}

.pricing-plan {
  flex: 1;
  min-width: 250px;
  max-width: 350px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pricing-plan:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.pricing-plan.premium {
  background: linear-gradient(135deg, rgba(74, 116, 220, 0.1) 0%, rgba(195, 60, 101, 0.1) 100%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 1;
}

.popular-badge {
  position: absolute;
  top: -12px;
  right: 20px;
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 600;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.plan-header {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.plan-name {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.9);
}

.plan-price {
  font-size: 2.5rem;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.9);
}

.plan-price span {
  font-size: 1rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
}

.plan-features {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 30px;
  flex: 1;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: left;
}

.feature-icon {
  font-size: 1rem;
  width: 20px;
  text-align: center;
}

.feature-icon.disabled {
  color: rgba(255, 77, 77, 0.8);
}

.pricing-plan .fa-check {
  color: #4caf50;
}

.pricing-plan.premium .fa-check {
  color: #64dd17;
}

.feature-text {
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.8);
}

.plan-button {
  padding: 12px 0;
  border-radius: 6px;
  border: none;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: auto;
}

.plan-button.free {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.8);
}

.plan-button.free:hover {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.3);
}

.plan-button.premium {
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.plan-button.premium:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
}

.plan-footnote {
  margin-top: 15px;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 768px) {
  .pricing-container {
    flex-direction: column;
    align-items: center;
  }
  
  .pricing-plan {
    width: 100%;
  }
}

/* FAQ Section */
.faq-section {
  margin: 60px auto;
  max-width: 800px;
  padding: 0 16px;
}

.faq-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 30px;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
}

.faq-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.faq-item {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.faq-item.active {
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.faq-question {
  padding: 18px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: rgba(255, 255, 255, 0.02);
}

.faq-question i {
  font-size: 0.9rem;
  transition: transform 0.3s ease;
  color: rgba(255, 255, 255, 0.7);
}

.faq-item.active .faq-question {
  background: linear-gradient(to right, rgba(74, 116, 220, 0.2), transparent);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out, padding 0.4s ease;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.95rem;
  line-height: 1.6;
}

.faq-answer.open {
  max-height: 500px;
  padding: 16px 20px;
}

.faq-answer p {
  margin: 0;
}

@media (max-width: 768px) {
  .faq-section {
    margin: 40px auto;
  }
  
  .faq-title {
    font-size: 1.3rem;
  }
  
  .faq-question {
    padding: 15px 16px;
    font-size: 0.95rem;
  }
  
  .faq-answer.open {
    padding: 12px 16px;
    font-size: 0.9rem;
  }
}
</style>
