<template>
  <div class="upload-container" @paste="handlePaste">
    <div
      class="dropzone"
      :class="{ 'active-dropzone': isDragging, 'error-dropzone': hasError, 'has-images': baseImages.length > 0 }"
      @dragenter="onDragEnter"
      @dragleave="onDragLeave"
      @dragover.prevent
      @drop="onDrop"
      @click="triggerFileInput"
    >
      <input
        type="file"
        id="files"
        multiple
        accept="image/jpeg,image/jpg,image/png,image/webp"
        @change="uploadImageSubmit"
        ref="imageFiles"
        class="file-input"
        aria-label="Upload images"
      />
      
      <!-- Show this when no images are uploaded -->
      <div v-if="baseImages.length === 0" class="dropzone-content">
        <div class="upload-icon">
          <i class="fa fa-cloud-upload"></i>
        </div>
        <h3 class="upload-title">Drag images here or click to browse</h3>
        <p class="upload-subtitle">Upload multiple images for better results</p>
        <p class="file-types">Accepted formats: JPG, JPEG, PNG, WEBP</p>
        <p class="paste-hint">You can also paste images from clipboard (Ctrl+V)</p>
      </div>
      
      <!-- Show this when images are uploaded -->
      <div v-if="baseImages.length > 0" class="preview-content">
        <h4 class="preview-title">Selected Images ({{ baseImages.length }})</h4>
        <div class="preview-grid">
          <div
            v-for="(image, index) in baseImages"
            :key="index"
            class="preview-item"
            @click.stop
          >
            <img :src="image" alt="Preview image" class="preview-image" />
            <button
              class="remove-button"
              @click.stop="removeImage(index)"
              title="Remove image"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
        
        <div class="add-more-hint">
          <i class="fa fa-plus-circle"></i>
          <span>Drag more images or click to browse</span>
        </div>
        
        <div class="inline-action-container">
          <button class="action-button inline-action" @click.stop="$emit('checkEmClicked', files)">
            <i class="fa fa-search"></i> Search for Matches
          </button>
        </div>
      </div>
      
      <p v-if="hasError" class="error-message">{{ errorMessage }}</p>
    </div>

  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  inject: ["mixpanel"],
  name: "image-uploader",
  components: {},
  props: {},
  data() {
    return {
      files: [],
      baseImages: [],
      isDragging: false,
      hasError: false,
      errorMessage: "",
      acceptedFileTypes: ["image/jpeg", "image/jpg", "image/png", "image/webp"],
    };
  },
  methods: {
    uploadImageSubmit() {
      this.clearError();

      const validFiles = this.filterValidFiles(this.$refs.imageFiles.files);

      if (validFiles.length === 0) {
        this.showError(
          "No valid image files selected. Please choose JPG, JPEG, PNG, or WEBP files."
        );
        return;
      }

      this.mixpanel.track("image_added", {
        count: validFiles.length,
      });

      // Update files array from the file input
      this.files = Array.from(this.$refs.imageFiles.files);
      
      // Regenerate all image previews
      this.baseImages = this.computeBaseImages();
    },
    computeBaseImages() {
      let baseImages = [];
      for (let i = 0; i < this.files.length; i++) {
        const url = URL.createObjectURL(this.files[i]);
        baseImages.push(url);
      }
      return baseImages;
    },
    removeImage(index) {
      this.baseImages.splice(index, 1);
      const dt = new DataTransfer();
      for (let i = 0; i < this.files.length; i++) {
        if (i !== index) {
          dt.items.add(this.files[i]);
        }
      }
      this.$refs.imageFiles.files = dt.files;
      this.mixpanel.track("image_removed");
      this.files = Array.from(dt.files);
    },
    onDragEnter(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    onDragLeave(e) {
      e.preventDefault();
      this.isDragging = false;
    },
    onDrop(e) {
      e.preventDefault();
      this.isDragging = false;
      this.clearError();

      if (e.dataTransfer.files.length) {
        const validFiles = this.filterValidFiles(e.dataTransfer.files);

        if (validFiles.length === 0) {
          this.showError(
            "No valid image files detected. Please use JPG, JPEG, PNG, or WEBP files."
          );
          return;
        }

        // Create DataTransfer including both existing and new files
        const dt = new DataTransfer();
        
        // Add existing files first
        for (let i = 0; i < this.files.length; i++) {
          dt.items.add(this.files[i]);
        }
        
        // Add new files
        for (let i = 0; i < validFiles.length; i++) {
          dt.items.add(validFiles[i]);
        }

        this.$refs.imageFiles.files = dt.files;
        this.uploadImageSubmit();
      }
    },
    triggerFileInput() {
      this.$refs.imageFiles.click();
    },
    filterValidFiles(fileList) {
      const validFiles = [];

      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        if (this.acceptedFileTypes.includes(file.type)) {
          validFiles.push(file);
        }
      }

      if (validFiles.length < fileList.length) {
        this.showError(
          `${
            fileList.length - validFiles.length
          } file(s) were rejected. Only JPG, JPEG, PNG, and WEBP formats are accepted.`
        );
      }

      return validFiles;
    },
    showError(message) {
      this.hasError = true;
      this.errorMessage = message;

      // Auto-clear error after 5 seconds
      setTimeout(() => {
        this.clearError();
      }, 5000);
    },
    clearError() {
      this.hasError = false;
      this.errorMessage = "";
    },
    handlePaste(event) {
      this.clearError();
      
      // Check if clipboard has items
      if (event.clipboardData && event.clipboardData.items) {
        const items = event.clipboardData.items;
        let imageFound = false;
        
        // Loop through clipboard items
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          
          // Check if item is an image
          if (item.type.indexOf('image') !== -1) {
            imageFound = true;
            
            // Get file from clipboard
            const file = item.getAsFile();
            
            // Check if file type is accepted
            if (this.acceptedFileTypes.includes(file.type)) {
              // Create a DataTransfer object to add the file
              const dt = new DataTransfer();
              
              // Add current files if any
              for (let j = 0; j < this.files.length; j++) {
                dt.items.add(this.files[j]);
              }
              
              // Add the new file
              dt.items.add(file);
              
              // Update file input and process the image
              this.$refs.imageFiles.files = dt.files;
              this.uploadImageSubmit();
              
              // Track the paste event
              this.mixpanel.track("image_pasted");
            } else {
              this.showError(
                `The pasted image format is not supported. Please use JPG, JPEG, PNG, or WEBP.`
              );
            }
            break;  // Process only the first image found
          }
        }
        
        if (!imageFound) {
          this.showError("No image found in clipboard. Try copying an image first.");
        }
      }
    },
  },
});
</script>

<style scoped>
.upload-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
}

.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed rgba(255, 255, 255, 0.3);
  border-radius: 12px;
  padding: 32px 16px;
  min-height: 220px;
  background-color: rgba(255, 255, 255, 0.05);
  cursor: pointer;
  transition: all 0.3s ease;
}

.dropzone-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.dropzone.has-images {
  padding: 16px;
  min-height: auto;
}

.preview-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropzone:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.5);
}

.active-dropzone {
  background-color: rgba(74, 116, 220, 0.2);
  border-color: #4a74dc;
}

.error-dropzone {
  background-color: rgba(220, 53, 69, 0.1);
  border-color: rgba(220, 53, 69, 0.7);
}

.upload-icon {
  font-size: 40px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 12px;
}

.upload-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
}

.upload-subtitle {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 4px;
}

.file-types {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 4px;
}

.paste-hint {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 4px;
}

.error-message {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.15);
  padding: 8px 12px;
  border-radius: 6px;
  margin-top: 12px;
  font-size: 13px;
  max-width: 90%;
  text-align: center;
}

.file-input {
  display: none;
}

.action-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.preview-title {
  font-size: 18px;
  margin-bottom: 16px;
}

.preview-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  gap: 16px;
  width: 100%;
  margin-bottom: 20px;
}

.preview-item {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  aspect-ratio: 3/4;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.preview-item:hover {
  transform: scale(1.03);
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.remove-button {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-button:hover {
  background-color: rgba(220, 53, 69, 0.8);
}

.action-button {
  padding: 10px 24px;
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
}

.info-panel {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 20px;
  border-radius: 12px;
  text-align: center;
}

.info-title {
  font-size: 24px;
  margin-bottom: 16px;
  color: #fff;
}

.info-title .highlight {
  background: linear-gradient(135deg, #4a74dc 0%, #c33c65 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.info-text {
  font-size: 15px;
  margin-bottom: 12px;
  color: rgba(255, 255, 255, 0.8);
}

.add-more-hint {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  margin-top: 8px;
  border-top: 1px dashed rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.7);
  width: 100%;
  font-size: 14px;
}

.add-more-hint i {
  color: #4a74dc;
  font-size: 16px;
}

.inline-action-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
  padding-top: 12px;
  border-top: 1px dashed rgba(255, 255, 255, 0.2);
}

.inline-action {
  padding: 10px 20px;
  width: auto;
  min-width: 180px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.inline-action i {
  font-size: 16px;
}

@media (min-width: 992px) {
  .upload-container {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .dropzone,
  .preview-container {
    flex: 1;
    min-width: 0;
  }

  .info-panel {
    flex-basis: 100%;
    margin-top: 20px;
  }
}
</style>
